<template>
  <b-card>
 <h2 class="mb-5">
    المكافئات
 </h2>

  
  <b-row v-for="item in profileData">
      <b-col
            cols="4"
            md="4"
          >
            <b-form-group
              label="المكافأة"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="item.reward_id.title"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Full Name -->
          <b-col
          cols="4"
            md="4"
          >
            <b-form-group
              label="التاريخ"
              label-for="full-name"
            >
              <b-form-input
                id="full-name"
                v-model="item.date"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
          cols="4"
            md="4"
          >
            <b-form-group
              label="الملاحظة"
              label-for="full-notes"
            >
              <b-form-input
                id="full-notes"
                v-model="item.notes"
                disabled
              />
            </b-form-group>
          </b-col>
      <!-- latest photo loop -->
     
      <!-- latest photo loop -->
    </b-row>
    <!--/ user suggestion  -->
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BLink, BImg,BButton,BFormInput,BFormGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BRow, BCol, BLink, BImg,BButton,BFormInput,BFormGroup
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      profileData: { },
    }
  },
  created() {
    let base_salary
    this.$http.get('/api/v1/get-user-rewards').then(res => {
      console.log(res.data)
      
      
      this.profileData = res.data.data
    
      console.log(this.profileData)
      
    })
}}
</script>
